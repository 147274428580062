<template>
    <div>
        <tournamentList :sport_id="sport_id"></tournamentList>
    </div>
</template>

<script>

import tournamentList from '@/components/ballsports/tournament/list.vue';

export default {
    components: {
        tournamentList
    },
    data () {
        let self = (window.x = this)
        return {
            sport_id: 19,
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {},
    computed: {}
}
</script>